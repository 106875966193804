import React from 'react'
import moment from 'moment'


const CostCenterNetworkListItem = ({modelListItem, index}) => {
  const {
    identifier,
    wbs,
    cost_center_identifier,
    active_at,
    expired_at,
    replacement_network,
    created_at,
  } = modelListItem

  return (
    <tr key={index}>
      <td>{identifier}</td>
      <td>{wbs !== null ? wbs : ' '}</td>
      <td>{cost_center_identifier}</td>
      <td>{active_at !== null ? moment(active_at).format('DD-MMM-yyyy') : ' '}</td>
      <td>{expired_at !== null ? moment(expired_at).format('DD-MMM-yyyy') : ' '}</td>
      <td>{replacement_network}</td>
      <td>{created_at !== null ? moment(created_at).format('DD-MMM-yyyy') : ' '}</td>
    </tr>
  )
}

export default CostCenterNetworkListItem